import React, { useContext, useState } from 'react';
import { useMediaQuery } from './shared-functions.js';
import { Col, Image, Row } from 'react-bootstrap';
import { ThemeContext } from "./Theme.js";
import toast, { Toaster } from 'react-hot-toast';
import { Button, Card, Heading, Text } from '@radix-ui/themes';
import { Lightbulb, Microphone, Subtitles, Translate, Waveform } from '@phosphor-icons/react';

export default function Home() {

  const { theme } = useContext(ThemeContext);
  let isPageWide = useMediaQuery('(min-width: 640px)');

  const [loading, setLoading] = useState(true);

  return (
    <div>

      <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: 400, background: 'linear-gradient(to bottom, rgba(255, 127, 80, 0.1), rgba(255, 255, 204, 0.07), rgba(255, 127, 80, 0))' }}></div>

      <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: 50, padding: 10, zIndex: 100 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginLeft: 0, marginRight: 0 }}>
          <Image src={'/logo.svg'} style={{ width: 140, cursor: 'pointer' }} />
          <Button variant="surface" size="2" onClick={() => window.open('https://app.verbanotes.com/', '_blank', 'noopener,noreferrer')}>Sign in</Button>
        </Row>
      </div>

      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 50, width: '100vw', height: '100vh', overflowY: 'auto' }}>

        <Col xs={12} sm={11} md={10} lg={10} xl={9} style={{ textAlign: 'center', maxWidth: 900 }}>
          <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: '10vh', marginBottom: 20 }}>
            <Waveform size={32} weight="regular" color="red" style={{ marginLeft: 5, marginRight: 5, marginBottom: 10 }} />
            {/* <ArrowRight size={16} weight="regular" style={{ marginLeft: 5, marginRight: 5 }} />
                <SquaresFour size={32} weight="regular" color="var(--accent-9)" style={{ marginLeft: 5, marginRight: 5 }} /> */}
            {/* <Text size='2' color="red">The Voice-based Meeting Note-taking Assistant</Text> */}
          </Row>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Text size={isPageWide ? '8' : '8'}>Voice-based meeting notes for busy people</Text>
          </div>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <Col xs={11} sm={11} md={10} lg={10} xl={8} style={{ textAlign: 'center' }}>
              <Text size={isPageWide ? '4' : '3'} color="gray">Focus on the conversation, and never miss a detail. Record your meetings and let Verbanotes turn them into articulate, structured notes.</Text>
            </Col>
          </Row>
          <Button variant="surface" size="3" style={{ marginTop: 40 }} onClick={() => window.open('https://app.verbanotes.com', '_blank', 'noopener,noreferrer')}>Get started for free</Button>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
            <Col xs={11} sm={11} md={10} lg={10} xl={8} style={{ textAlign: 'center' }}>
              <Text size='1' color="gray">No credit card required</Text>
            </Col>
          </Row>

          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 100 }}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: 'center', padding: 10 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                <div style={{ width: 30, marginTop: -1 }}>
                  <Microphone size={16} weight="bold" color="var(--accent-9)" />
                </div>
                <div style={{ width: `calc(100% - 30px)`, textAlign: 'left' }}>
                  <Heading size='3' style={{ marginBottom: 0 }}>Record</Heading>
                  <Text size='2' color="gray" as="p" style={{ marginTop: 5 }}>Team meetings, 1-on-1s, events, customer interviews, board meetings, and even your own ramblings, rants, or journal entries - don't miss a detail.</Text>
                </div>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: 'center', padding: 10 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                <div style={{ width: 30, marginTop: -3 }}>
                  <Subtitles size={16} weight="bold" color="var(--accent-9)" />
                </div>
                <div style={{ width: `calc(100% - 30px)`, textAlign: 'left' }}>
                  <Heading size='3' style={{ marginBottom: 0 }}>Transcribe</Heading>
                  <Text size='2' color="gray" as="p" style={{ marginTop: 5 }}>Verbanotes transcribes your meeting audio recordings and formats them into a template of your choice with action items, questions, feedback, and more.</Text>
                </div>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: 'center', padding: 10 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                <div style={{ width: 30, marginTop: -1 }}>
                  <Lightbulb size={16} weight="bold" color="var(--accent-9)" />
                </div>
                <div style={{ width: `calc(100% - 30px)`, textAlign: 'left' }}>
                  <Heading size='3' style={{ marginBottom: 0 }}>Transform</Heading>
                  <Text size='2' color="gray" as="p" style={{ marginTop: 5 }}>Instantly turn your notes into engaging blog posts, slide deck content, tweets, twitter threads, newsletters, and more.</Text>
                </div>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: 'center', padding: 10 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                <div style={{ width: 30, marginTop: -1 }}>
                  <Translate size={16} weight="bold" color="var(--accent-9)" />
                </div>
                <div style={{ width: `calc(100% - 30px)`, textAlign: 'left' }}>
                  <Heading size='3' style={{ marginBottom: 0 }}>Translate</Heading>
                  <Text size='2' color="gray" as="p" style={{ marginTop: 5 }}>Translate your notes to 10+ popular languages, including Spanish, French, German, Italian, Japanese, Korean, Portuguese, Russian, Hindi, and more.</Text>
                </div>
              </Row>
            </Col>
          </Row>

          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 60 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
              <Text size='1' color="gray">Works with your choice of LLMs</Text>
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
                  <Image src="/assets/icons/llama.png" alt="Meta LLama" width={24} height={24} style={{ marginLeft: 10, marginRight: 10 }} />
                  <Image src="/assets/icons/mistral.png" alt="Mistral" width={24} height={24} style={{ marginLeft: 10, marginRight: 10 }} />
                  <Image src="/assets/icons/anthropic.png" alt="Anthropic" width={24} height={24} style={{ marginLeft: 10, marginRight: 10 }} />
                  <Image src="/assets/icons/gemini.png" alt="Gemini" width={24} height={24} style={{ marginLeft: 10, marginRight: 10 }} />
                  <Image src="/assets/icons/openai.png" alt="OpenAI" width={24} height={24} style={{ marginLeft: 10, marginRight: 10 }} />
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: isPageWide ? 100 : 50 }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
                  <Image src="/assets/sample.png" alt="Sample note" width={'100%'} style={{ marginTop: 0, objectFit: 'contain' }} />
                </Col>
              </Row> */}

          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 100 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
              <Text size='8' style={{ marginBottom: 0 }}>Pricing</Text>
            </Col>
          </Row>

          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 40 }}>
            <Col xs={11} sm={11} md={11} lg={6} xl={6} style={{ padding: 10 }}>
              <Card style={{ width: '100%', padding: 20, marginTop: 10, minHeight: 300 }}>
                <Text size="4" color="accent" style={{ marginBottom: 0 }}>Free</Text>
                <div style={{ width: '100%' }}><Text size="2" variant="soft" color="gray">$0 per month</Text></div>
                <ul style={{ marginTop: 10 }}>
                  <li><Text size="2">10 one-time credits</Text></li>
                  <li><Text size="2">Record up to 1 minute per note</Text></li>
                  <li><Text size="2">Unlimited notes</Text></li>
                  <li><Text size="2">100MB total storage limit</Text></li>
                  <li><Text size="2">Email support</Text></li>
                </ul>
              </Card>
            </Col>
            <Col xs={11} sm={11} md={11} lg={6} xl={6} style={{ padding: 10 }}>
              <Card style={{ width: '100%', padding: 20, marginTop: 10, minHeight: 300 }}>
                <Text size="4" color="accent" style={{ marginBottom: 0 }}>Pro</Text>
                <div style={{ width: '100%' }}><Text size="2" variant="soft" color="gray">$10 per month</Text></div>
                <ul style={{ marginTop: 10 }}>
                  <li><Text size="2">1,000 credits per month</Text></li>
                  <li><Text size="2">Additional credits $0.10 each</Text></li>
                  <li><Text size="2">Record up to 10 minutes per note</Text></li>
                  <li><Text size="2">Unlimited notes</Text></li>
                  <li><Text size="2">1GB total storage limit</Text></li>
                  <li><Text size="2">Email support</Text></li>
                  <li><Text size="2">Cancel anytime</Text></li>
                </ul>
              </Card>
            </Col>
          </Row>

          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 200, marginBottom: 200 }}>
            <Col xs={11} sm={11} md={10} lg={10} xl={8} style={{ textAlign: 'center', maxWidth: 900 }}>
              <Button variant="surface" size="3" style={{ marginTop: 40 }} onClick={() => window.open('https://app.verbanotes.com', '_blank', 'noopener,noreferrer')}>Get started for free</Button>
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
                <Col xs={10} sm={10} md={10} lg={10} xl={8} style={{ textAlign: 'center' }}>
                  <Text size='1' color="gray">No credit card required</Text>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 100 }}>
            <Col style={{ textAlign: 'center' }}>
              <Text size='1' color="gray">Copyright 2024, Comaker Labs.</Text>
            </Col>
          </Row>

          <div style={{ height: 200 }}></div>

        </Col>
      </Row>
      <Toaster position='bottom-right' toastOptions={{ className: 'toast', style: { background: 'var(--gray-3)', color: 'var(--gray-11)' } }} />
    </div>
  )

}

